import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { GLBufferAttribute, NoToneMapping } from 'three';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let loadingAnimate = document.querySelector('.loading');

window.addEventListener('load', () => {
    gsap.timeline()
    .to('.loading-text:before', {
        duration: 2,
        width: 100
    })

    .to('.loading-text', {
        duration: 1,
        translateX: -5000
    })
    .to('.loading', {
        duration: 1,
        opacity: 0,
    })
    .to('.loading', {
        display: 'none'
    })

    gsap.from(camera.position, {
        duration: 2,
        z: -70,
        ease: 'expo',
        delay: 2.5,
    })
    
    gsap.from('.hero-first-text', {
        duration: 2,
        x: -2000,
        ease: 'back',
        delay: 3,
    })
    
    gsap.from('.hero-main-text', {
        duration: 2,
        x: -2000,
        ease: 'back',
        delay: 3.1
    })
    
    gsap.from('.hero-second-text', {
        duration: 2,
        x: -2000,
        ease: 'back',
        delay: 3.2
    })
    
    gsap.from('.hero-btn', {
        duration: 2,
        x: -2000,
        ease: 'back',
        delay: 3.2
    })
    
    gsap.from('.hero-socials img', {
        duration: 2,
        x:2000,
        ease:'back',
        stagger: 0.1,
        delay: 3,
    })
})

// Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Model
const modelLoader = new GLTFLoader()

let mixer = null
let mixerSquare = null

modelLoader.load('models/quantum_cube/scene.gltf', (gltf) => {
    scene.add(gltf.scene)

    gltf.scene.position.z = -18
    gltf.scene.position.x = 7

    gltf.scene.rotation.y = -2

    mixer = new THREE.AnimationMixer(gltf.scene)
    const action = mixer.clipAction(gltf.animations[0])
    action.play()

    console.log(gltf)
})

modelLoader.load('models/square_shift/scene.gltf', (gltf) => {
    scene.add(gltf.scene)

    gltf.scene.position.z = 8 
    gltf.scene.position.x = 2

    mixerSquare = new THREE.AnimationMixer(gltf.scene)
    const action = mixerSquare.clipAction(gltf.animations[0])
    action.play()

    console.log(gltf)
})

// Objects
// const geometry = new THREE.TorusGeometry( .7, .2, 16, 100 );

// Materials

// const material = new THREE.MeshBasicMaterial()
// material.color = new THREE.Color(0xff0000)

// Mesh
// const sphere = new THREE.Mesh(geometry,material)
// scene.add(sphere)

// Lights

const ambientLight = new THREE.AmbientLight(0xffffff, 0.3)
ambientLight.position.x = 2
ambientLight.position.y = 3
ambientLight.position.z = 4
scene.add(ambientLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 2
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// renderer.outputEncoding = THREE.sRGBEncoding;

/**
 * Cursor
 */
 const cursor = {}
 cursor.x = 0
 cursor.y = 0

 window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})

/**
 * Animate
 */

const clock = new THREE.Clock()
let previousTime = 0


const tick = () =>
{

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Animate camera

    const parallaxX = cursor.x
    const parallaxY = cursor.y
    camera.position.x = parallaxX * 3
    camera.position.y = - parallaxY * 3


    // Animate Model

    if(mixer)
    {
        mixer.update(deltaTime * 0.7)
    }

    if(mixerSquare)
    {
        mixerSquare.update(deltaTime * 0.7)
    }

    // Update Orbital Controls
    // controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

/**
 *  HTML ANIMATIONS
 */

// Navigation Toggle Animation
const toggle = document.querySelector('.toggle')
const navToggle = document.querySelector('.nav-list')

toggle.addEventListener('click', () => {
    toggle.classList.toggle('active')
    navToggle.classList.toggle('nav-list-toggle')
})

navToggle.addEventListener('click', () => {
    toggle.classList.toggle('active')
    navToggle.classList.remove('nav-list-toggle')
})



document.querySelector('.about-btn').addEventListener('click', () => {
    document.querySelector('.about-text').classList.remove('display-none')

    gsap.to(camera.rotation, {
        duration: 2,
        y: -3
    })

    gsap.to('.about-text', {
        duration: 2,
        translateX: -100,
    } )

    gsap.to('.hero-text', {
        duration: 2,
        translateX: '-2000px'
    })
})

document.querySelector('.home-btn').addEventListener('click', () => {
    
    

    gsap.to('.about-text', {
        duration: 1,
        translateX: '2000px'
    })

    gsap.to(camera.rotation, {
        duration: 1,
        y: 0
    })

    
    gsap.timeline()
    .to('.hero-text', {
        duration: 2,
        translateX: 0,
    } )

    .add(function() {
        document.querySelector('.about-text').classList.add('display-none')
    })
})

// MEDIA QUERY ANIMATIONS

ScrollTrigger.matchMedia({

    "(max-width: 576px)": function() {
        gsap.from('.work-text-wrapper', {
            scrollTrigger:{
                trigger: '.work-text-wrapper',
                start: "top bottom",
            },
            minHeight: '2vh',
            duration: 2,
        })
    },
    
    "(min-width: 577px)": function () {
        gsap.from('.work-container', {
            scrollTrigger:{
                trigger: '.work-text-wrapper',
                start: "center center",
            },
            width: '45%',
            duration: 2,
        })
    }
})

